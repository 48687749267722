import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

import {
  MapsService
} from 'src/app/services/maps.service';
import * as mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import {
  environment
} from 'src/environments/environment';
import {
  GeoJSONSource
} from 'mapbox-gl';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-pin-on-map',
  templateUrl: './pin-on-map.component.html',
  styleUrls: ['./pin-on-map.component.scss'],
  host:{class:'pin-on-map-container'}
})
export class PinOnMapComponent implements OnInit {
  map: mapboxgl.Map;
  marker:any;
  addipid_city:any;
  addipid_district:any;
  addipid_state:any;
  addipid_country:any;
  addipid_pincode:any;
  addipid_landmark:any;
  addipid_sublocality:any;
  addipid_locality:any;
  addipid:any;
  addipid_addgeoid:any;
  session_data:any;
  basemap_type:any;
  search_locality:any;
  landmarkControl = new FormControl();
  subLclitControl =  new FormControl();
  LoclityControl =  new FormControl();
  twrMapCtrl =  new FormControl();
  typeMapCtrl = new FormControl();
  addipid_TwrList: any;
  flrMapCtrl = new FormControl();
  addipid_AptList: any;
  actual_addipd_Aptlist: any;
  addipid_FloorList: any; 
  addipid_type: any = ['Residential', 'Non-Residential'];
  addipid_flathouse: any;
  global_floor_info: { [key: number]: any } = {};// declare a global variable to store floor info
  floor_filteredOptions: Observable<string[]>;

  // validation_score related fields
  mismatch : any;
  validation_score : any;
  flag : any;
  could_not_validate : any;

  isActive: string = 'getAddress'; // Initially set the main tab to 'getAddress'
  mapHeight: string = 'none'
  isFlexEnabled: boolean = false;

  actual_address_text:any;
  actual_primary_details_text:any;
  actual_pincode:any;

  modified_primary_details_text:any;
  modified_address_text:any;
  missing_fields:any;

  addipid_fulladdress:any;
  isDisplayed_fullAddress: boolean = false;  // Initially, the class is hidden.
  contains_spectial_charecters = false;

  toggleActive(tab: string) {
  this.isActive = tab;
  if(tab != 'getAddress'){
    this.isFlexEnabled = true;
  }else{
    this.isFlexEnabled = false;
    this.mapHeight = 'none';
  }
  }
  toggleDisplay() {
    this.isDisplayed_fullAddress = !this.isDisplayed_fullAddress;  // Toggle the display state.
  }
  
  checkForSpecialCharacters(inputValue: string) {
    this.contains_spectial_charecters = false;
    // Regular expression to detect special characters
    const regex = /[^A-Za-z0-9\s(),-]|([A-Za-z0-9])\1{4,}/;

    if (regex.test(inputValue)) {
      this.contains_spectial_charecters=true;
      console.log(inputValue);
      alert("cannot contain special characters.");
    }
  }

  //  below is the pincode filtering and intiativies
  addipid_allStates: string[] = [
    'Andaman & Nicobar Islands','Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chandigarh','Chhattisgarh','Dadra & Nagar Haveli, Gujarat','Daman & Diu','Goa','Gujarat','Haryana','Himachal Pradesh','Jammu & Kashmir','Jharkhand','Karnataka','Kerala','Lakshadweep','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland','Nct Of Delhi','Odisha','Puducherry','Puducherry, Andhra Pradesh','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal'
  ];
  addipid_allPincodes: any = [];
  filteredPincodes: any = [];

    // This method will filter pincodes based on user input
    filterPincodes(): void {
      const query = this.addipid_pincode.toLowerCase();
      if (query) {
        this.filteredPincodes = this.addipid_allPincodes.filter(pincode => 
          pincode.toString().toLowerCase().includes(query)
        );
      } else {
        this.filteredPincodes = [];
      }
    }

     // This method selects a pincode and assigns it to the model
  selectPincode(pincode: string): void {
    this.addipid_pincode = pincode;
    this.filteredPincodes = [];
  }

  //  End of pincode filtering and intiativies


  constructor(
    private mapsService: MapsService,
    private spinner: NgxSpinnerService
  )
   { }

  ngOnInit(): void {

    mapboxgl as typeof mapboxgl;
    this.map = new mapboxgl.Map({
      accessToken: environment.mapbox.accessToken,
      container: 'map',
      style: {
        version: 8,
        sources: {},
        layers: [],
      },
      zoom: 17,
      maxZoom:17
    });

    
    // initialise session data
    this.session_data = {}
    // change the cursor to pointer
    this.map.getCanvas().style.cursor = "pointer";
    // add marker div element
    const markerDiv = document.createElement('div');
    this.marker = new mapboxgl.Marker(markerDiv)
    markerDiv.style.height = '30px'
    markerDiv.style.width = '30px'
    markerDiv.style.backgroundImage = "url('assets/imgs/marker.png')"
    markerDiv.style.backgroundSize = "contain"

   
    
   this.map.setCenter([78.34884623396074, 17.39098977672866]); 



    this.map.on('load', () => { 
    this.gpsLocation();
    // add OSM Vector and satellite basemap and set visibility of vector map to visible by default
    this.map.addSource("osm-satellite", { "type": "raster", "tiles": ["https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"], "tileSize": 256 }); 
    this.map.addLayer({ "type": "raster", "id": 'osm-satellite-map', "source": "osm-satellite" }); 
    https://openmaptiles.github.io/osm-bright-gl-style/#17.35/17.426141/78.456206
    this.map.addSource("osm-street", { "type": "raster", "tiles": ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"], "tileSize": 256 }); 
    this.map.addLayer({ "type": "raster", "id": 'osm-street-map', "source": "osm-street" });

    // add google vector and satellite
    this.map.addSource("google-street", { "type": "raster", "tiles": ["https://mt0.google.com/vt/lyrs=r&hl=en&x={x}&y={y}&z={z}"], "tileSize": 256}); 
    this.map.addLayer({ "type": "raster", "id": 'google-street-map', "source": "google-street" });     

    this.map.addSource("google-satellite", { "type": "raster", "tiles": ["https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"], "tileSize": 256}); 
    this.map.addLayer({ "type": "raster", "id": 'google-satellite-map', "source": "google-satellite" }); 



    this.map.setLayoutProperty('osm-satellite-map', 'visibility', 'none');
    this.map.setLayoutProperty('osm-street-map', 'visibility', 'visible');
    this.map.setLayoutProperty('google-satellite-map', 'visibility', 'none');
    this.map.setLayoutProperty('google-street-map', 'visibility', 'none');

    this.map.on('click', (e) => {
      this.marker.remove();
      // change the mouse cursor icon to hand          
      if (this.map.getZoom() <= 17.5){
        // this.get_encodea(e.lngLat.lng.toString(), e.lngLat.lat.toString())
        // new mapboxgl.Marker(el).setLngLat([e.lngLat.lng,e.lngLat.lat])        
        // perform reverse geocoding and get the address to format in our address area
        // let clicked = [e.lngLat.lng,e.lngLat.lat] as any;
        // let address = this.geocode_location(clicked)

        this.marker.setLngLat([e.lngLat.lng,e.lngLat.lat]).addTo(this.map);
        let x = e.lngLat.lng as any
        let y = e.lngLat.lat as any

        // clear the input boxes
        [this.addipid_landmark, this.addipid_AptList, this.addipid_city, this.addipid_district, this.addipid_state, this.addipid_addgeoid, this.addipid_pincode, this.addipid_country] = '';
       
        // this.landmarkControl.setValue('');
        // this.subLclitControl.setValue('');
        // this.LoclityControl.setValue('');

        // perform encoding service
        this.encode_service(x,y);
        // this.loc_intelligence(x,y);
        this.session_data["costum_location"]=[x,y];
      }
    })
  })

  }

  check_pinid(pinid: any): Promise<string> {
    this.spinner.show(); // Show spinner
    return new Promise((resolve, reject) => {
      this.mapsService.isPinidFlagged([pinid]).subscribe(
        (data) => {
          console.log(data.result);
          this.spinner.hide();
          if (data.result === 'flagged') {
            resolve('yes');
          } else {
            resolve('no');
          }
        },
        (error) => {
          console.error(error);
          this.spinner.hide();
          reject(error);
        }
      );
    });
  }
  

  onStateChange(event:any){
    // free the pincode box
    this.spinner.show(); // Show spinner
    this.addipid_pincode = '';
    const stateName = event.target.value;
    this.mapsService.pincode_finder([stateName]).subscribe(
      data => {
        this.addipid_allPincodes = data
        this.spinner.hide(); // Hide spinner after data is retrieved
      },
      error => {
        this.spinner.hide(); // Hide spinner on error as well
      }
    )
  }
  onFloorOptionSelected(event: any) {
    const selectedFlrKey = event.option.value;
    if (selectedFlrKey in this.global_floor_info) {
    this.twrMapCtrl.setValue(this.global_floor_info[selectedFlrKey][0]);
    this.addipid_TwrList = this.global_floor_info[selectedFlrKey];
    } else {
      this.twrMapCtrl.setValue(null);
      this.addipid_TwrList = [];
    }
    // Your custom logic here
  }
  gpsLocation() {
    navigator.geolocation.getCurrentPosition(resp => {
      //add marker
      this.marker.remove();
      this.marker.setLngLat([resp.coords.longitude, resp.coords.latitude]).addTo(this.map);
      this.map.flyTo({
        center: [resp.coords.longitude, resp.coords.latitude],
        zoom: 16
      })
    // decode the location to addipid
    let location = [resp.coords.longitude, resp.coords.latitude] as any
    if(this.basemap_type == "gle"){
      this.mapsService.g_geocode([resp.coords.longitude, resp.coords.latitude],'reverse').subscribe(
        data => {
          console.log(data)
          if(data[0].address_components.length == 1){
            // this.addressText = ""
            // this.showAddressBar = false;
            return
          }       
          let add = {}
              for (var i = Object.keys(data).length-1; i >= 0; i--){
                  let locs = data[i].address_components              
                  for (let k = 0; k < locs.length; k++) {
                    if (add[locs[k].types[0]] == undefined){
                        add[locs[k].types[0]] =  locs[k].long_name
                    }
                    if (typeof locs[k].types[1] != 'undefined') {
                      if (add[locs[k].types[1]] == undefined){
                        add[locs[k].types[1]] =  locs[k].long_name
                    }
                    }
                    if (typeof locs[k].types[2] != 'undefined') {
                      if (add[locs[k].types[2]] == undefined){
                        add[locs[k].types[2]] =  locs[k].long_name
                    }
                    }                
                  }
              }
              // console.log(add)
              this.addipid_locality = [add['sublocality_level_1']];
              // this.addipid_sublocality = add['sublocality_level_2'];
              this.addipid_landmark = [add['landmark']];

              //also fill the controls
              this.landmarkControl.setValue(add['landmark']);
              this.LoclityControl.setValue(add['sublocality_level_1']);

              this.addipid_city = add['locality'];
              this.addipid_district = add['administrative_area_level_3'];
              this.addipid_state = add['administrative_area_level_1'];
              this.addipid_country = add['country'];
              this.addipid_addgeoid = this.get_addipid(resp.coords.longitude, resp.coords.latitude);
              this.session_data["costum_location"]=[resp.coords.longitude, resp.coords.latitude]

        }
      )

    } else {
      // perform encoding service
      this.encode_service(resp.coords.longitude, resp.coords.latitude);
    }
     },
     err => {
       console.log(err);
     });
  }

  get_addipid(long,lat){
    this.mapsService.getAddress(long,lat).subscribe(
      (data: any) => {
        this.addipid_addgeoid = data["addipid"]
        let pcode = data["addipid"].split(".")[0]
              if(pcode.includes("/")){
                this.addipid_pincode = pcode.slice(0,-2);
              } else{
                this.addipid_pincode = pcode;
              }
      },
      (error: any) => {
        alert("some issue. please click on map and try again.")
        // window.location.href = 'https://verifypinid.auth.us-east-1.amazoncognito.com/login?client_id=6qd3i6390mia6qpdes4pemq26h&redirect_uri='+environment.redirect_url+'&response_type=code';
      }
    )
  }

  create_polygon_from_minmaxcoords(data: any, crs: string) {
    if (crs == "mercator") {
      let min_xy = [data[0], data[2]]
      let max_xy = [data[1], data[3]]
      var min_loc = turf.toWgs84(turf.point([min_xy[0], min_xy[1]]))
      var max_loc = turf.toWgs84(turf.point([max_xy[0], max_xy[1]]))
      // Define minimum and maximum latitude and longitude coordinates
    }
    let minLng = min_loc.geometry.coordinates[0];
    let minLat = min_loc.geometry.coordinates[1];
    let maxLng = max_loc.geometry.coordinates[0];
    let maxLat = max_loc.geometry.coordinates[1];
    // Create an array of coordinates for the polygon
    let polygonCoords = [
      [minLng, minLat],
      [maxLng, minLat],
      [maxLng, maxLat],
      [minLng, maxLat],
      [minLng, minLat]
    ];
    return turf.polygon([polygonCoords]);
  }

  
  get_decodea(x: string, y: string) {    
    this.mapsService.getdecodebox(x, y).subscribe(
      (data: any) => {
        // console.log(data)
        if (data[0],data[1],data[2],data[3]==0){
          alert("Please enter the correct ADDIPID");
          return
        }

        let polygon = this.create_polygon_from_minmaxcoords(data,'mercator') as any
        // console.log(polygon);
        // (this.map.getSource('selectedCell') as GeoJSONSource).setData(polygon);  
        // Get the centroid of the polygon using Turf.js
        const centroid = turf.center(polygon) as any
        // this.map.panTo(centroid.geometry.coordinates);
        // add marker
        this.marker.remove();
        this.marker.setLngLat([centroid.geometry.coordinates[0],centroid.geometry.coordinates[1]]).addTo(this.map);
        this.map.flyTo({
          center: [centroid.geometry.coordinates[0],centroid.geometry.coordinates[1]],
          zoom: 17
        });   
        
        // perform encoding service
        this.encode_service(centroid.geometry.coordinates[0],centroid.geometry.coordinates[1]);

        // get the high level address using Nominatim reverse geocoding service
        // this.geocode_location(centroid.geometry.coordinates);   
      },
      (error: any) => {
        alert("Please Navigate to home and Authenticate.")
        // window.location.href = 'https://verifypinid.auth.us-east-1.amazoncognito.com/login?client_id=6qd3i6390mia6qpdes4pemq26h&redirect_uri='+environment.redirect_url+'&response_type=code';
      }
    )
  }

  get_encodea(){
      this.mapsService.g_geocode(this.search_locality,"direct").subscribe(
        data => {  
          console.log("hello...this is from direct geocoding")
          console.log(data)      
          let locs = data
        // this.marker.remove();
        // this.marker.setLngLat([locs.lng, locs.lat]).addTo(this.map);
        //   this.map.flyTo({
        //     center: [locs.lng, locs.lat],
        //     zoom: 17
        //   })
          // call the encoding service
          this.encode_service(locs["lng"], locs["lat"]);
          // call location intellegence
          // this.loc_intelligence(locs.lng, locs.lat);
        }
      )
      
  }

  getSearchLocations() {  
    let locationText = this.search_locality;
    let cords = [] as any
    let letters = /[a-zA-Z]/g;
    let lettersExist = letters.test(locationText);

    // creating regex 
    let count_dots = locationText.split('.').length - 1
    if (count_dots >= 3) {
      this.get_decodea(locationText, 'ind')
      return
    }

    //if letter exists then fire google api geocoding and center the map to that location

    if (lettersExist == true) {
      this.search_locality = locationText;
      this.get_encodea();
      return
    }


    typeof locationText === 'string' ? cords = locationText.trim().split(',') : '';


    // this.map.setCenter([cords[1], cords[0]])
    this.map.flyTo({
      center: [cords[1], cords[0]],
      zoom: 17
    });
    // convert that to addipid by sending the location to encode
    let location = [cords[1], cords[0]] as any
    // -------------------------------------------------have to check from here -------------------------------
    this.encode_service(location[0],location[1])
    // also geocode the location
    // this.geocode_location(location)
    // add marker
    this.marker.remove();
    this.marker.setLngLat([cords[1], cords[0]]).addTo(this.map);
  }

  // loc_intelligence(long,lat,add_data=[]){
  //   // alert("hi")
  //   this.mapsService.loc_intel(long,lat,add_data).subscribe(
  //     data => {
  //       console.log(data)
  //       this.addipid_AptList = data["apartment"]
  //       // this.addipid_sublocality = data["sub_locality"]
  //       this.addipid_locality = data["locality"]
  //       this.addipid_landmark = data["landmark"]

  //       // above is for auto pops when user click the text box
  //       // now fill the inputboxes with first values
  //       this.landmarkControl.setValue(data["landmark"][0]);
  //       this.subLclitControl.setValue(data["locality"][0]);
        
  //     }
  //   )
  // }
// this is to save the csutomer modified address


address_verification(address_to_verify){
  this.spinner.show(); // Show spinner
  this.mapsService.address_verification(address_to_verify).subscribe(
    data => {
      this.mismatch = this.validation_score = this.flag = this.could_not_validate = "";
      console.log(data);
      this.mismatch = data['validation']['incorrect_addressField'].join(', ');
      this.validation_score = data['validation']['validation_score'];
      this.could_not_validate = data['validation']['could_not_validate'].join(', ');

      
      let pcode = data["pinid"].split(".")[0]
      if(pcode.includes("/")){
        this.addipid_pincode = pcode.slice(0,-2);
      } else{
        this.addipid_pincode = pcode;
      }
      this.addipid_addgeoid = data["pinid"];
      // hide the spinner
      this.spinner.hide(); // Show spinner
      // grab the location and set it on map / save to session
      let location = JSON.parse(data['location'])
      const x= location['res']['lng'];
      const y= location['res']['lat'];
      this.session_data["costum_location"] = [x,y]
      this.marker.remove();
      this.marker.setLngLat([x,y]).addTo(this.map);
      this.map.flyTo({
        center: [x,y],
        zoom: 17
      });
      // ask whether to save it to database
      Swal.fire({
        // title: 'Your Validation Score is  '+data['validation']['validation_score'].toFixed(2),
        title: 'Do you Want to Save the Address',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Save!',
      }).then((result) => {
        if (result.isConfirmed) {
            // Call your function here
            this.save_the_address();
            return;
        }
    });
      

    },
    error => {
      this.spinner.hide(); // Hide spinner on error as well
      alert("Issue occured during verification.")
    }
  )
}

// clear all the input fileds
clearTheFields(){
  this.twrMapCtrl.setValue(null);
  this.flrMapCtrl.setValue(null);
  this.typeMapCtrl.setValue(null);
  this.LoclityControl.setValue(null);
  this.landmarkControl.setValue(null);
  this.addipid_AptList = null;
  this.addipid_city = null;
  this.addipid_district = null;
  this.addipid_state = null;
  this.addipid_pincode = null;
  this.addipid_country = null;
  this.addipid_addgeoid = null;
  this.addipid_fulladdress = null;
  this.missing_fields=null;
}


  loc_modification(long,lat,add_data=[]){
    // alert("hi")
    this.spinner.show(); // Show spinner
    for (let i = 0; i < add_data.length; i++) {
      if (typeof add_data[i] === 'undefined' || add_data[i]==null) {
        add_data[i] = '';
      }
  }
    console.log(add_data)
    this.mapsService.loc_modification(long,lat,add_data).subscribe(
      data => {
        console.log(data);
        let resp = data as unknown as string
        this.spinner.hide(); // Hide spinner after data is retrieved
        if (resp == "Insertion successful!"){
          alert("Submitted Successfully!!!");
          // clear all the input fields
          this.clearTheFields();
        }
      },
      error => {
        this.spinner.hide(); // Hide spinner on error as well
        alert("Issue occured while submission.")
      }
    )
  }

  getFirstTwoValuesFromArray<T>(arr: T[]): [T?, T?] {
    if (arr.length > 0) {
        const firstValue = arr[0];
        const secondValue = arr.length > 1 ? arr[1] : undefined;
        return [firstValue, secondValue];
    }
    return [undefined, undefined];
}

  encode_service(long,lat){    
    // set the floor and tower_shop values to null
    this.twrMapCtrl.setValue(null);
    this.flrMapCtrl.setValue(null);
    this.typeMapCtrl.setValue(null);
    this.addipid_flathouse = null;
        // call the encode service
        if(this.basemap_type == "gle"){
          this.mapsService.g_geocode([long,lat],'reverse').subscribe(
            data => {
              console.log(data)
              if(data[0].address_components.length == 1){
                // this.addressText = ""
                // this.showAddressBar = false;
                return
              }  
              
              let add = {}
              for (var i = Object.keys(data).length-1; i >= 0; i--){
                  let locs = data[i].address_components              
                  for (let k = 0; k < locs.length; k++) {
                    if (add[locs[k].types[0]] == undefined){
                        add[locs[k].types[0]] =  locs[k].long_name
                    }
                    if (typeof locs[k].types[1] != 'undefined') {
                      if (add[locs[k].types[1]] == undefined){
                        add[locs[k].types[1]] =  locs[k].long_name
                    }
                    }
                    if (typeof locs[k].types[2] != 'undefined') {
                      if (add[locs[k].types[2]] == undefined){
                        add[locs[k].types[2]] =  locs[k].long_name
                    }
                    }                
                  }
              }
              console.log(add)
              this.addipid_locality = [add['sublocality_level_1']];
              // this.addipid_sublocality = [add['sublocality_level_2']];
              this.addipid_landmark = [add['landmark']];

              //also fill the controls
              this.landmarkControl.setValue(add['landmark']);
              this.LoclityControl.setValue(add['sublocality_level_1']);

              this.addipid_city = add['locality'];
              this.addipid_district = add['administrative_area_level_3'];
              this.addipid_state = add['administrative_area_level_1'];
              this.addipid_country = add['country'];
              this.addipid_addgeoid = this.get_addipid(long,lat);
              this.session_data["costum_location"]=[long,lat];
            }
          )
        } else {
          // use these scope variables for drop down listing
          const dropdown_maxLength = 4;
          let scope_locality = [];
          let scope_landmark = [];


          //  get information from loc_intel table and set the inputs on priority
          this.spinner.show(); // Show spinner
          console.log(long,lat);
          this.mapsService.loc_intel(long,lat, lat).subscribe( // the other lat is just dummy
            data => {
              console.log(data);
              //set tower and floor
              this.addipid_FloorList = [0,1,2,3,4,5];
              this.flrMapCtrl.setValue(this.addipid_FloorList[0]);
              if ('floor_info' in data){
              if (Object.keys(data['floor_info']).length != 0) {
                this.global_floor_info = data['floor_info'] as any;
                if(Object.keys(data['floor_info'])[0]=='0'){ //check if it has zero floor data. if so, add the value to twrMap
                  this.twrMapCtrl.setValue(Object.values(data['floor_info'])[0][0]);
                }
              };
              }

              this.addipid_AptList = data["apartment"].length > 0 ? data["apartment"][0] : ''
              // this.addipid_sublocality = data["sub_locality"]
              // fill the scope variables to take first 2 values from crowd sourced table
              scope_locality = data["locality"].length > 0 ? this.getFirstTwoValuesFromArray(data['locality']) : []
              scope_landmark = data["landmark"].length > 0 ? this.getFirstTwoValuesFromArray(data['landmark']) : []
              // remove any undefined data in the array 
              scope_locality = scope_locality.filter(value => value !== undefined); 
              scope_landmark = scope_landmark.filter(value => value !== undefined); 
      
              // above is for auto pops when user click the text box
              // now fill the inputboxes with first values
              this.landmarkControl.setValue(data["landmark"].length > 0 ? scope_landmark[0] : '');
              this.LoclityControl.setValue(data["locality"].length > 0 ? scope_locality[0] : '');

              // remove is there are any duplicates in the scope_landmark add by crowd source
              scope_landmark = Array.from(new Set(scope_landmark))
              scope_locality = Array.from(new Set(scope_locality))
              
            }
          )

          
          this.mapsService.getAddress(long,lat).subscribe(
            (data: any) => {
              console.log(data)
              // clear the input boxes
              this.addipid_locality, this.addipid_AptList, this.addipid_sublocality, this.addipid_landmark, this.addipid_city= '';
              this.addipid_district, this.addipid_state, this.addipid_country, this.addipid_addgeoid = '';

              // prioratise locality data over localities data that is coming from backend
              if ("locality" in data && data["locality"] != null){
                let elementsToAdd = Math.min(dropdown_maxLength - scope_locality.length, data["locality"].length);
                // Add the elements from data['locality'] to scope_locality varaiable
                scope_locality = scope_locality.concat(data["locality"].slice(0, elementsToAdd));

                this.addipid_locality = scope_locality; // finally add the dropdown list with max 4
                this.LoclityControl.setValue(scope_locality[0]);
              } else if("localities" in data && data["localities"] != null) {
                let locality_from_pincodes = data["localities"].replace(/'/g, '"'); //removing double quotes to parse
                locality_from_pincodes = JSON.parse(locality_from_pincodes)
                if (locality_from_pincodes.length > 0){
                  let elementsToAdd = Math.min(dropdown_maxLength - scope_locality.length, locality_from_pincodes.length);
                  // Add the elements from data['locality'] to scope_locality varaiable
                  scope_locality = scope_locality.concat(locality_from_pincodes.slice(0, elementsToAdd));

                  // fill the inputbox with locality first value
                  this.LoclityControl.setValue(scope_locality[0]);
                }
               
              }

              //now work on landmark dropdown
              if ("landmark" in data && data["landmark"] != null){
                let elementsToAdd = Math.min(dropdown_maxLength - scope_landmark.length, data["landmark"].length);
                scope_landmark = scope_landmark.concat(data["landmark"].slice(0, elementsToAdd));
              }

              //check the addipid_AptList if its empty and adjust accordingly
              if (this.addipid_AptList == '' && scope_landmark.length > 0 && !scope_landmark[0].includes('*')){
                this.landmarkControl.setValue(scope_landmark[0]) // because value without * indicating near by place is not given to addipd_Aptlist input
              } else if (this.addipid_AptList == '' && scope_landmark.length > 1 && scope_landmark[0].includes('*')){
                this.landmarkControl.setValue(scope_landmark[1]) // because we give 0 index value to addipid_Aptlist since the 0 index includes *
              } else if (this.addipid_AptList != '' && scope_landmark.length > 0){
                this.landmarkControl.setValue(scope_landmark[0]) // since addipid_Aptlist is already filled by Loc_intel service, give the 0 index value
              }

              // check if apartment name is already filled by loc_intel table | else fill with encode_service output
              this.addipid_AptList = this.addipid_AptList == '' ? scope_landmark.length > 0 && scope_landmark[0].includes('*') ? scope_landmark[0][0] : '' : this.addipid_AptList
              this.actual_addipd_Aptlist = this.addipid_AptList;

              
              
              
              this.addipid_city = data["region/city"]
              this.addipid_district = data["district"]
              this.addipid_state = data["state"]
              this.addipid_country = data["country"]
              // remove if there are any duplicates for landmark
              if (scope_landmark.length > 0){
                let cleanedArray =  scope_landmark.map(item => String(item).replace(',*', '').toUpperCase());
                scope_landmark = Array.from(new Set(cleanedArray))
              }
              

              this.addipid_landmark = scope_landmark;

              // remove if there are any duplicates for locality
              if (scope_locality.length > 0){
                let cleanedArray =  scope_locality.map(item => String(item).replace(',*', '').toUpperCase());
                scope_locality = Array.from(new Set(cleanedArray))
              }

              this.addipid_locality = scope_locality;

              let pcode = data["addipid"].split(".")[0]
              if(pcode.includes("/")){
                this.addipid_pincode = pcode.slice(0,-2);
              } else{
                this.addipid_pincode = pcode;
              }
              this.addipid_addgeoid = data["addipid"];

              //take the actual address returned from backend
              this.actual_address_text=this.addipid_AptList+', '+this.LoclityControl.value+', '+this.addipid_city+', '+this.addipid_pincode+', '+this.addipid_state+', '+this.addipid_country
              this.actual_primary_details_text = this.twrMapCtrl.value+''+(this.flrMapCtrl.value != 0 ? ', '+this.flrMapCtrl.value:'')+', '+this.typeMapCtrl.value+', '+this.addipid_flathouse
              this.actual_pincode = pcode
              // concat the above to full address line as well
              
              this.addipid_fulladdress = this.actual_primary_details_text + ','+ this.actual_address_text
              // Step 1: Remove the "null," entries
              this.addipid_fulladdress = this.addipid_fulladdress.replace(/null,/g, '');

              // Step 2: Remove any extra commas that might remain, including leading or trailing commas
              this.addipid_fulladdress = this.addipid_fulladdress.replace(/^,|,$/g, ''); // Remove any leading or trailing commas
              this.addipid_fulladdress = this.addipid_fulladdress.replace(/,,+/g, ',');   // Remove consecutive commas
              // also check the missing fields
              this.checkMissingFields();
              // derive the latlng based on bbox
              this.marker.remove();
              let bbox_data = data["bbox"]
              if (bbox_data[0],bbox_data[1],bbox_data[2],bbox_data[3]==0){
                alert("Please enter the correct ADDIPID");
                return
              }
      
              let polygon = this.create_polygon_from_minmaxcoords(bbox_data,'mercator') as any
              // console.log(polygon);
              // (this.map.getSource('selectedCell') as GeoJSONSource).setData(polygon);  
              // Get the centroid of the polygon using Turf.js
              const centroid = turf.center(polygon) as any
              // this.map.panTo(centroid.geometry.coordinates);
              // add marker
              this.marker.setLngLat([centroid.geometry.coordinates[0],centroid.geometry.coordinates[1]]).addTo(this.map);
              this.map.flyTo({
                center: [centroid.geometry.coordinates[0],centroid.geometry.coordinates[1]],
                zoom: 17
              });   
    
              console.log(centroid)

              this.session_data["costum_location"]=[centroid.geometry.coordinates[0],centroid.geometry.coordinates[1]]
              this.spinner.hide(); // Hide spinner
            },
            (error: any) => {
              console.log("some issue. please click on map and try again.")
              this.spinner.hide(); // Hide spinner
              // window.location.href = 'https://verifypinid.auth.us-east-1.amazoncognito.com/login?client_id=6qd3i6390mia6qpdes4pemq26h&redirect_uri='+environment.redirect_url+'&response_type=code';
            }
          )

        }
  }
  
checkMissingFields(){
  this.missing_fields =''
  // this.typeMapCtrl.value == null? this.missing_fields += 'Address Type/':''
  this.addipid_flathouse == null? this.missing_fields += 'Building No/':''
  this.flrMapCtrl.value == null? this.missing_fields += 'Floor/':''
  this.twrMapCtrl.value == ''? this.missing_fields += 'Tower/':''
  this.addipid_AptList == ''? this.missing_fields += 'Building Name/':''
  this.LoclityControl.value == ''? this.missing_fields += 'Locality/':''
  this.addipid_city == ''? this.missing_fields += 'City/':''
  // remove the last '/' 
  this.missing_fields = this.missing_fields != '' ? this.missing_fields.slice(0, -1):this.missing_fields
}
  save_the_address(){
    // now post the modified user input to database
    let x = this.session_data['costum_location'][0]
    let y = this.session_data['costum_location'][1]


    // just set values to '' if they are null
    this.subLclitControl.value === null && this.subLclitControl.setValue('');
    this.landmarkControl.value === null && this.landmarkControl.setValue('');
    this.LoclityControl.value === null && this.LoclityControl.setValue('');


    let modified_data = [this.addipid_AptList, this.subLclitControl.value, this.LoclityControl.value, this.landmarkControl.value,
    this.addipid_city, this.addipid_district, this.addipid_state, this.addipid_country, this.addipid_pincode, this.twrMapCtrl.value, this.flrMapCtrl.value, this.typeMapCtrl.value, this.addipid_flathouse, this.mismatch, this.validation_score, this.flag, this.could_not_validate]
    
    // check if the modified data before submitting contains any special charecters.
    // Loop through each value and check for special characters
    for (let value of modified_data) {
      this.checkForSpecialCharacters(String(value)) 
        // Stop the process if a special character is found
        if (this.contains_spectial_charecters==true){
          return;
        }
      
    }

    // send the modified data to backend

    this.loc_modification(x,y,modified_data);
  }

  //  check and proceed based on pincode mismatch
  pincode_mismatch_alert(){
    Swal.fire({
      title: 'PINCODE MISMATCH',
      text: 'Do you Still want to Submit ?',
      icon: 'warning',
      showCancelButton: false,
      showDenyButton: true, // Add this for a second button
      confirmButtonText: 'No',
      denyButtonText: 'Submit', // He denined and want to submit at any cost
    }).then((result) => {
      if (result.isConfirmed) {
        this.flag = "";
        return;
      } else if (result.isDenied) {
        // Call your function for the second action
        this.save_the_address(); // Replace this with your other function
        return;
      }
    });
  }
  // function fired on submitting button to check the location
  async check_location() {

    // check if the pinid is a flagged or falling near to flagged pinids
    // let is_flagged:any = this.check_pinid(this.addipid_addgeoid)
    try {
      // Ensure `check_pinid` returns an Observable
    const is_flagged: string = await this.check_pinid(this.addipid_addgeoid); // Await the result
    console.log(is_flagged);
    if (is_flagged == 'yes') {
      Swal.fire({
        title: 'Location Flagged',
        text: 'Do Delivery at your location',
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'OKay',
      }).then(() => {
        // Ensure no further actions are taken after this Swal
        return;
      });
      return; // This ensures the function exits after displaying the first Swal
    }

    // alert("hehe")
  //  let loc_distance =  turf.distance(this.session_data['location'], this.session_data['costum_location'])
  //  if (loc_distance >.05){
  //   alert("Hey, you are ordering for a location which is "+loc_distance+" KM away from your current location. Are you sure to proceed?")
  //  }
  // check if the editor has changed any text and want to submit
  // console.log(this.landmarkControl.value[0])
  // if ([this.landmarkControl.value[0], this.LoclityControl.value].includes(null)){
  //   alert("Please Select all inputs.");
  //   return
  // } 

  // when user enters , its a text and when it is populated byour tables, its an array object
    if (typeof(this.landmarkControl.value) == 'object' && this.landmarkControl.value != null){
      this.landmarkControl.setValue(this.landmarkControl.value[0]);
    }

  let foundInAnyArray = 'true';
  const act_arrays = [this.addipid_AptList, this.addipid_landmark, this.addipid_locality];
  const valuesToCheck = [this.addipid_AptList, this.landmarkControl.value, this.LoclityControl.value, this.twrMapCtrl.value, this.flrMapCtrl.value, this.typeMapCtrl.value, this.addipid_flathouse];
// check if user entered new value. if so then only proceed to saving.
// console.log(valuesToCheck);
// console.log(act_arrays);
// console.log(this.actual_addipd_Aptlist);
  if (this.actual_addipd_Aptlist != undefined && valuesToCheck[0].toUpperCase() != this.actual_addipd_Aptlist.toUpperCase()) {foundInAnyArray = 'false'}
  console.log(foundInAnyArray)

  if (valuesToCheck[1] != null && !act_arrays[1].some(item =>
    item.toUpperCase().includes(valuesToCheck[1].toUpperCase())
  )) {
  foundInAnyArray = 'false';
}
  // console.log(foundInAnyArray)

  if (valuesToCheck[2] != null && !act_arrays[2].some(item =>
    item.toUpperCase().includes(valuesToCheck[2].toUpperCase())
  )) {
  foundInAnyArray = 'false';
}

//  console.log(foundInAnyArray)
  // console.log(valuesToCheck[3])
  // check if floor is added. if not simply set it to default ground floor, i.e zero.
  // this.flrMapCtrl.value == null ? this.flrMapCtrl.setValue(this.addipid_FloorList[0]):'';

  this.modified_primary_details_text = this.twrMapCtrl.value+','+this.flrMapCtrl.value+','+this.typeMapCtrl.value+','+this.addipid_flathouse
  this.modified_address_text = this.addipid_AptList+','+this.LoclityControl.value+','+this.addipid_city+','+this.addipid_pincode+','+this.addipid_state+','+this.addipid_country

  if (this.actual_primary_details_text != this.modified_primary_details_text)  {foundInAnyArray = 'false'}
  if (this.actual_address_text != this.modified_address_text)  {foundInAnyArray = 'address_changed'}
  // console.log(this.actual_address_text);
  // console.log(this.modified_address_text);
  // console.log(foundInAnyArray)

// collect the missing fields and concatinate their field names to popup the user
this.checkMissingFields();
// end of collect the missing fields and concatinate their field names to popup the user

// console.log(this.addipid_pincode)
if(this.addipid_pincode == ''){
  alert("Please Add Pincode");
  return;
} else if (this.addipid_pincode == '000000'){
  alert("Invalid Pincode");
  return;
} else {
  const regex = /^[0-9]{6}$/; // Regex to allow only 6 digits and only numerics
  const isValid = regex.test(this.addipid_pincode);
  if (isValid == false){
    alert("Invalid pincode");
    return;
  }
}
if (foundInAnyArray == 'address_changed'){
  let poptext = this.missing_fields != '' ? 'Missing--'+this.missing_fields:'';
  Swal.fire({
    title:  `<p>Address Changed</p>`,
    html: `<p style='color:red'>`+poptext+`</p>`,
    icon: 'warning',
    showCancelButton: true,
    showDenyButton: true,
    cancelButtonText: 'Add/Change', // Change cancel button text here
    confirmButtonText: 'Submit',
    denyButtonText: 'Add-On-Map',
    reverseButtons: true, // This will swap the order of the buttons
    customClass: {
      confirmButton: 'my-confirm-button',
      denyButton:'my-deny-button'
  }
}).then((result) => {
    if (result.isConfirmed) {
      // Call your function for the second action
      if (this.actual_pincode != this.addipid_pincode){
        this.flag = 'pincode'; //flag the mismatch
        console.log(this.actual_pincode,this.addipid_pincode);
        this.pincode_mismatch_alert();
      } else {
        this.save_the_address();
      }
      return;
    } else if (result.isDenied) {
      // Call your function for the confirmed action
      this.toggleActive('pinOnMap');
      this.address_verification(this.modified_address_text);
      return;
    }
  });
  return; // on pressing cancel button
}
  if(foundInAnyArray == 'false' && this.addipid_pincode != ''){
    this.save_the_address();
    
  } else {
      alert("You have not added any New data")
    
  }

  
  


  


    
    // let add_data = {} as any
    // add_data[]
    // ssss = this.addipid_addgeoid 
    // ssss = this.addipid_aptvilla_name
    // ssss = this.addipid_landmark
    // ssss = this.addipid_sublocality
    // ssss = this.addipid_locality 
    // ssss = this.addipid_city
    // ssss = this.addipid_district
    // ssss = this.addipid_state
    // ssss = this.addipid_pincode
    // ssss = this.addipid_addgeoid
  //  this.loc_intelligence(x,y);
} catch (error) {
  console.error('Error occurred in check_location:', error);
}
  }
  
  
  switchBasemap(event, val) {
    event.preventDefault()
    this.basemap_type = 'osm'   

    switch (val) {
      case 'street': {   
        document.getElementById("satellitebasemap").style.zIndex = '1';
        document.getElementById("vectorbasemap").style.zIndex = '-9';  
        this.map.setLayoutProperty('osm-satellite-map', 'visibility', 'none');
        this.map.setLayoutProperty('google-satellite-map', 'visibility', 'none');
        this.map.setLayoutProperty('google-street-map', 'visibility', 'none');
        this.map.setLayoutProperty('osm-street-map', 'visibility', 'visible');
        break;
      }
      case 'satellite': {
        document.getElementById("satellitebasemap").style.zIndex = '-9';
        document.getElementById("vectorbasemap").style.zIndex = '1';
        this.map.setLayoutProperty('osm-street-map', 'visibility', 'none');
        this.map.setLayoutProperty('google-satellite-map', 'visibility', 'none');
        this.map.setLayoutProperty('google-street-map', 'visibility', 'none');        
        this.map.setLayoutProperty('osm-satellite-map', 'visibility', 'visible');
        break;
      }
      default: {
        document.getElementById("satellitebasemap").style.zIndex = '1';
        document.getElementById("vectorbasemap").style.zIndex = '-9';
        this.map.setPaintProperty('super-mesh','line-color','#a6a6a6');
        break;
      }
    }
  }



  switch_G_Basemap(event, val) {
    event.preventDefault()
    this.basemap_type = 'google'   

    switch (val) {
      case 'street': {   
        document.getElementById("g_satellitebasemap").style.zIndex = '1';
        document.getElementById("g_vectorbasemap").style.zIndex = '-9';  
        this.map.setLayoutProperty('osm-satellite-map', 'visibility', 'none');
        this.map.setLayoutProperty('google-satellite-map', 'visibility', 'none');
        this.map.setLayoutProperty('google-street-map', 'visibility', 'visible');
        this.map.setLayoutProperty('osm-street-map', 'visibility', 'none');
        break;
      }
      case 'satellite': {
        document.getElementById("g_satellitebasemap").style.zIndex = '-9';
        document.getElementById("g_vectorbasemap").style.zIndex = '1';
        this.map.setLayoutProperty('osm-street-map', 'visibility', 'none');
        this.map.setLayoutProperty('google-satellite-map', 'visibility', 'visible');
        this.map.setLayoutProperty('google-street-map', 'visibility', 'none');        
        this.map.setLayoutProperty('osm-satellite-map', 'visibility', 'none');
        break;
      }
      default: {
        document.getElementById("g_satellitebasemap").style.zIndex = '1';
        document.getElementById("g_vectorbasemap").style.zIndex = '-9';
        this.map.setPaintProperty('super-mesh','line-color','#a6a6a6');
        break;
      }
    }
  }



}
function list(arg0: any): any {
  throw new Error('Function not implemented.');
}

function firstValueFrom(arg0: Observable<string>) {
  throw new Error('Function not implemented.');
}

